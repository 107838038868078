/* eslint-disable tailwindcss/no-contradicting-classname */

import * as React from "react";

import { Document } from "@contentful/rich-text-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import {
  HolidayCMTabbedContent,
  HolidayCMTabbedContentSlideItem
} from "@src/lib/services/server/contentful";

import {
  ProductContainer,
  TrendReportOneColumnText,
  RichText
} from "@src/components";

import { filterNull } from "@src/lib/utils";

import "swiper/css/pagination";
import "swiper/css";

export type HolidayTabbedContentProps = {
  content: HolidayCMTabbedContent;
};

const ImageWithText = ({ slide }: HolidayCMTabbedContentSlideItem) => {
  const desktopOrientation =
    slide?.imagePosition == "Left" ? "imageonleft" : "imageonright";
  const mobileOrientation =
    slide?.imageMobilePosition == "Bottom" ? "imageonbottom" : "imageontop";

  const imageColClassNames: { [key: string]: string } = {
    imageonleft: "order-1 md:mx-0 md:col-span-2 md:col-start-1 md:col-end-2",
    imageonright: "order-2 md:mx-0 md:col-span-2 md:col-start-2 md:col-end-3"
  };

  const textColClassNames: { [key: string]: string } = {
    imageonleft: "md:col-start-2 md:col-end-3 md:justify-self-start",
    imageonright: "md:col-start-1 md:col-end-2 md:justify-self-end",
    imageonbottom: "order-1",
    imageontop: "order-2"
  };

  const imageColClassName = imageColClassNames[desktopOrientation];
  const textColClassName = textColClassNames[desktopOrientation];
  const mobileTextColClassName = textColClassNames[mobileOrientation];

  return (
    <div className="mb-12 grid content-center items-center gap-5 md:grid-flow-col-dense md:grid-cols-2">
      {slide?.image && (
        <div className={`col-span-full ${imageColClassName}`}>
          <figure className="relative w-full">
            <img
              src={slide.image.url ?? ""}
              alt={slide.image.description ?? ""}
              className="rounded-3xl"
            />
          </figure>
        </div>
      )}
      <div
        className={`col-span-full flex flex-col md:col-span-2 md:max-w-[696px] ${mobileTextColClassName} ${textColClassName}`}
      >
        {slide?.eyebrow && (
          <p className="type-pr-theta md:type-pr-eta text-pr-dark-gray-400">
            {slide.eyebrow}
          </p>
        )}
        <div
          className={`space-y-2 lg:space-y-4 ${
            slide?.eyebrow ? "mt-1.5 lg:mt-3" : ""
          }`}
        >
          <h2 className="text-2xl lg:text-5xl">{slide?.heading}</h2>
          {slide?.text && (
            <div className="prose prose-pr prose-pr-gray-bullets lg:text-lg">
              <RichText text={slide.text.json as Document} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const HolidayTabbedContent = ({ content }: HolidayTabbedContentProps) => {
  const { heading, eyebrow, html, ctaStyle, slidesCollection, sys } = content;
  const items = filterNull(slidesCollection?.items);
  const id = sys.id;

  const paginationClassName = id ? `pagination-${id}` : "pagination";

  const defaultCtaStyle: string = "teal";

  const ctaClassNames: { [key: string]: string } = {
    red: "swiper-pagination-bullet-trend-red",
    green: "swiper-pagination-bullet-trend-green",
    yellow: "swiper-pagination-bullet-trend-yellow",
    teal: "swiper-pagination-bullet-trend-teal"
  };

  const ctaActiveClassNames: { [key: string]: string } = {
    red: "swiper-pagination-bullet-active-trend-red",
    green: "swiper-pagination-bullet-active-trend-green",
    yellow: "swiper-pagination-bullet-active-trend-yellow",
    teal: "swiper-pagination-bullet-active-trend-teal"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];
  const ctaActiveClassName =
    ctaActiveClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <ProductContainer>
      <div className="w-full">
        <TrendReportOneColumnText
          heading={heading}
          eyebrow={eyebrow}
          html={html}
        />
        <div
          className={`mt-8 flex flex-row flex-wrap items-center justify-center gap-4 pb-5 md:flex-row lg:gap-7 lg:pb-8 ${paginationClassName}`}
        ></div>
        <Swiper
          pagination={{
            el: `.${paginationClassName}`,
            clickable: true,
            bulletActiveClass: ctaActiveClassName,
            bulletClass: ctaClassName,
            renderBullet: function (index, className) {
              return `<span class="${className}"> ${
                items[index]?.cta as string
              } </span>`;
            }
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <ImageWithText slide={item.slide} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </ProductContainer>
  );
};

export { HolidayTabbedContent };
