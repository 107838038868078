import React, { useRef } from "react";
import Plyr, { APITypes } from "plyr-react";
import "plyr-react/dist/plyr.css";
import getVideoId from "get-video-id";
import { Document } from "@contentful/rich-text-types";

export type PlyrVideoProps = {
  video?: string | null;
  caption?: Document;
};

export function PlyrVideo({ video }: PlyrVideoProps) {
  const ref = useRef<APITypes>(null);
  const typeParam = "video" as const;
  let providerParam;
  if (!video) {
    return null;
  }
  const videoData = getVideoId(video);
  if (!videoData.id || !videoData.service) {
    return null;
  }

  switch (videoData.service) {
    case "youtube":
      providerParam = "youtube" as const;
      break;
    case "vimeo":
      providerParam = "vimeo" as const;
      break;
    default:
      return null;
  }

  const source = {
    type: typeParam,
    sources: [
      {
        src: videoData.id,
        provider: providerParam
      }
    ]
  };

  const videoOptions = {
    controls: ["play", "current-time", "progress", "volume", "fullscreen"],
    volume: 5
  };

  const plyrVideo = <Plyr ref={ref} source={source} options={videoOptions} />;

  return <div>{plyrVideo}</div>;
}

export default PlyrVideo;
