import * as React from "react";
import {
  ProductContentModulesCta,
  ProductContentModulesVideo,
  ProductContentModulesServices,
  ProductContentModulesStatistics,
  ProductContentModulesImageWithText,
  ProductContentModulesComparisonTable,
  ProductContentModulesCards,
  ProductContentModulesTransparentCards,
  ProductContentModulesClientsCarousel,
  ProductContentModulesSolutionFeatures,
  ProductContentModulesTestimonials,
  ProductContentModulesFeaturedImageWithText,
  ProductContentModulesPillars,
  ProductContentModulesAccordion,
  ProductContentModulesImage,
  ProductContentModulesImageCarousel,
  ProductContentModulesRichText,
  ProductContentModulesClientsLinks,
  ProductContentModulesHeadingWithText,
  ProductContentModulesTable,
  ProductContentModulesCaseStudyWithCard,
  ProductContentModulesHtml,
  ProductCtaButtonType,
  ProductCtaDemoRequestType,
  ProductCtaFormType,
  ProductContentModulesCtaModal,
  ProductContentModulesDropdownBlock,
  ComparisonTable,
  Card,
  CaseStudy,
  ContentModule,
  ProductContentModulesRtGetStarted,
  ProductContentModulesRtGetStartedForm,
  ProductContentModulesRtGetStartedThankYou,
  ProductContentModulesWebinarBlock,
  ProductContentModulesGatedContent,
  ProductContentModulesSubscribeForm,
  ProductContentModulesCtaImageWithText,
  PricingContentModulesImageWithText,
  PricingContentModulesTabbedContent,
  PricingContentModulesGrid,
  ProductContentModulesComparisonChart,
  ToadCalculator,
  TrendReportContentModulesColumnCards,
  TrendReportContentModulesTabbedContent,
  ProductContentModulesClientsCarouselFilter
} from "@src/lib/services/server/contentful";

import {
  ProductCtaBlock,
  ProductVideoBlock,
  ProductServicesBlock,
  ProductStatisticsBlock,
  ProductImageWithTextBlock,
  ProductComparisonTableBlock,
  ProductCardsBlock,
  ProductClientsCarouselBlock,
  ProductSolutionFeaturesBlock,
  ProductTestimonialsBlock,
  ProductFeaturedImageWithTextBlock,
  ProductPillarsBlock,
  ProductAccordionBlock,
  ProductImageBlock,
  ProductImageCarouselBlock,
  ProductRichTextBlock,
  ProductClientsLinksBlock,
  ProductHeadingWithTextBlock,
  ProductCaseStudyWithCardBlock,
  ProductTableBlock,
  ProductHtmlBlock,
  ProductRtGetStartedBlock,
  ProductTransparentCardsBlock,
  ProductRtGetStartedThankYou,
  ProductWebinarBlock,
  TrendReportColumnCards,
  TrendReportTabbedContent,
  ProductFilterButton
} from "@src/components";
import { Document } from "@contentful/rich-text-types";
import { filterNull } from "@src/lib/utils";
import { ProductCtaModal } from "../ProductCtaModal";
import { ProductDropdownBlock } from "../ProductDropdownBlock";
import { ProductGatedContent } from "../ProductGatedContent";
import { PricingImageWithTextBlock } from "../PricingImageWithTextBlock";
import { PricingTabbedContent } from "../PricingTabbedContent";
import { PricingGridBlock } from "../PricingGridBlock";
import { ProductSubscribeFormBlock } from "../ProductSubscribeFormBlock";
import { ProductCtaImageWithTextBlock } from "../ProductCtaImageWithText";
import ProductComparisonChartBlock from "../ProductComparisonChartBlock/ProductComparisonChartBlock";
import { ProductToadCalculator } from "../ProductToadCalculator";
import Arrow from "@/img/icons/arrow.svg";

import { Navigation, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/navigation";
import "swiper/css";

export type ProductContentModulesProps = {
  contentModules: ContentModule[];
};

const renderCta = (contentModule: ProductContentModulesCta) => {
  const cta = contentModule.cta as
    | ProductCtaButtonType
    | ProductCtaDemoRequestType
    | ProductCtaFormType;
  return <ProductCtaBlock key={contentModule.sys.id} cta={cta} />;
};

const renderVideo = (contentModule: ProductContentModulesVideo) => {
  return (
    <ProductVideoBlock
      key={contentModule.sys.id}
      heading={contentModule.heading}
      video={contentModule.video}
      caption={contentModule.caption?.json as Document | undefined}
      orientation={contentModule.orientation?.toLowerCase().split(" ").join("")}
      backgroundStyle={contentModule.backgroundStyle
        ?.toLowerCase()
        .split(" ")
        .join("")}
    />
  );
};

const renderServices = (contentModule: ProductContentModulesServices) => {
  const items = filterNull(contentModule.servicesCollection?.items);

  return (
    <ProductServicesBlock
      key={contentModule.sys.id}
      heading={contentModule.heading}
      ctaTitle={contentModule.ctaTitle}
      ctaUrl={contentModule.ctaUrl}
      items={items}
    />
  );
};

const renderRtGetStartedThankYou = (
  contentModule: ProductContentModulesRtGetStartedThankYou
) => {
  return (
    <ProductRtGetStartedThankYou
      key={contentModule.sys.id}
      heading={contentModule.heading}
      subtext={contentModule.subtext?.json as Document}
      image={contentModule.image}
      calendlyHeading={contentModule.calendlyHeading?.json as Document}
      calendlySlug={contentModule.calendlySlug}
    />
  );
};

const renderWebinarBlock = (
  contentModule: ProductContentModulesWebinarBlock,
  index: number,
  length: number
) => {
  const items = filterNull(contentModule.webinarCardsCollection?.items);
  return (
    <ProductWebinarBlock
      key={contentModule.sys.id}
      heading={contentModule.heading}
      numberOfVisibleCards={contentModule.numberOfVisibleCards}
      items={items}
      length={length}
      index={index}
    />
  );
};

const renderGatedContent = (
  contentModule: ProductContentModulesGatedContent
) => {
  return (
    <ProductGatedContent
      key={contentModule.sys.id}
      image={contentModule.image}
      form={contentModule.form}
    />
  );
};

const renderPricingTabbedContent = (
  contentModule: PricingContentModulesTabbedContent
) => {
  const slides = filterNull(contentModule.slidesCollection?.items);
  return (
    <PricingTabbedContent
      key={contentModule.sys.id}
      bannerHeading={contentModule.bannerHeading}
      bannerSubheading={contentModule.bannerSubheading}
      table={contentModule.table?.json as Document | undefined}
      ctaText={contentModule.ctaText}
      ctaUrl={contentModule.ctaUrl}
      slides={slides}
    />
  );
};

const renderPricingGrid = (contentModule: PricingContentModulesGrid) => {
  const items = filterNull(contentModule.modulesCollection?.items);
  return (
    <PricingGridBlock
      heading={contentModule.heading}
      mainCtaTitle={contentModule.mainCtaTitle}
      mainCtaUrl={contentModule.mainCtaUrl}
      includedHeading={contentModule.includedHeading}
      includedDescription={contentModule.includedDescription}
      modulesTitle={contentModule.modulesTitle}
      modulesSubheading={contentModule.modulesSubheading}
      items={items}
      leftCtaTitle={contentModule.leftCtaTitle}
      leftCtaUrl={contentModule.leftCtaUrl}
      rightCtaTitle={contentModule.rightCtaTitle}
      rightCtaUrl={contentModule.rightCtaUrl}
      popularAddOns={contentModule.popularAddOns?.json as Document | undefined}
      image={contentModule.image}
    />
  );
};

const renderSubscribeForm = (
  contentModule: ProductContentModulesSubscribeForm
) => {
  return (
    <ProductSubscribeFormBlock
      key={contentModule.sys.id}
      image={contentModule.image}
      marketoFormId={contentModule.marketoFormId}
      formHeading={contentModule.formHeading}
      subheading={contentModule.subheading}
      formTheme={contentModule.formTheme?.toLowerCase().split(" ").join("")}
      finePrint={contentModule.finePrint?.json as Document | undefined}
    />
  );
};

const renderPricingImageWithText = (
  contentModule: PricingContentModulesImageWithText
) => {
  return (
    <PricingImageWithTextBlock
      key={contentModule.sys.id}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      image={contentModule.image}
      subtext={contentModule.subtext}
      backgroundStyle={contentModule.backgroundStyle
        ?.toLowerCase()
        .split(" ")
        .join("")}
    />
  );
};

const renderCtaImageWithText = (
  contentModule: ProductContentModulesCtaImageWithText
) => {
  return (
    <ProductCtaImageWithTextBlock
      image={contentModule.image}
      text={contentModule.text?.json as Document | undefined}
    />
  );
};

const renderComparisonChart = (
  contentModule: ProductContentModulesComparisonChart
) => {
  const items = filterNull(contentModule.tablesCollection?.items);
  const logos = filterNull(contentModule.logosCollection?.items);
  return (
    <ProductComparisonChartBlock
      key={contentModule.sys.id}
      items={items}
      logos={logos}
    />
  );
};

const renderToadCalculator = (contentModule: ToadCalculator) => {
  return (
    <ProductToadCalculator
      key={contentModule.sys.id}
      heading={contentModule.heading}
      subheading={contentModule.subheading}
      description={contentModule.description?.json as Document | undefined}
      subtext={contentModule.subtext}
    />
  );
};

const renderStatistics = (contentModule: ProductContentModulesStatistics) => {
  const items = filterNull(contentModule.statisticsCollection?.items);
  return <ProductStatisticsBlock key={contentModule.sys.id} items={items} />;
};

const renderImageWithText = (
  contentModule: ProductContentModulesImageWithText
) => {
  const image = contentModule.image;
  const primaryIcon = contentModule.primaryIcon;
  const secondaryIcon = contentModule.secondaryIcon;
  const items = filterNull(contentModule.servicesCollection?.items);

  return (
    <ProductImageWithTextBlock
      key={contentModule.sys.id}
      eyebrow={contentModule.eyebrow}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      image={image}
      video={contentModule.video}
      primaryIcon={primaryIcon}
      secondaryIcon={secondaryIcon}
      secondaryText={contentModule.secondaryText?.json as Document | undefined}
      ctaTitle={contentModule.ctaTitle}
      ctaUrl={contentModule.ctaUrl}
      ctaStyle={contentModule.ctaStyle?.toLowerCase().split(" ").join("")}
      ctaId={contentModule.ctaId}
      orientation={contentModule.orientation?.toLowerCase().split(" ").join("")}
      servicesLabel={contentModule.servicesLabel}
      items={items}
    />
  );
};

const renderComparisonTable = (
  contentModule: ProductContentModulesComparisonTable
) => {
  const table = contentModule.comparisonTable as ComparisonTable;

  return (
    <ProductComparisonTableBlock key={contentModule.sys.id} table={table} />
  );
};

const renderCards = (contentModule: ProductContentModulesCards) => {
  const items = filterNull(contentModule.cardsCollection?.items);
  const table = contentModule.comparisonTable as ComparisonTable;

  return (
    <ProductCardsBlock
      key={contentModule.sys.id}
      id={contentModule.sys.id}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      ctaTitle={contentModule.ctaTitle}
      ctaUrl={contentModule.ctaUrl}
      comparisonTableCtaTitle={contentModule.comparisonTableCtaTitle}
      table={table}
      items={items}
    />
  );
};

const renderTransparentCards = (
  contentModule: ProductContentModulesTransparentCards
) => {
  const items = filterNull(contentModule.cardsCollection?.items);
  const table = contentModule.comparisonTable as ComparisonTable;

  return (
    <ProductTransparentCardsBlock
      key={contentModule.sys.id}
      id={contentModule.sys.id}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      ctaTitle={contentModule.ctaTitle}
      ctaUrl={contentModule.ctaUrl}
      comparisonTableCtaTitle={contentModule.comparisonTableCtaTitle}
      table={table}
      items={items}
    />
  );
};

const renderClientsCarousel = (
  contentModule: ProductContentModulesClientsCarousel
) => {
  const items = filterNull(contentModule.clientsCollection?.items);
  return (
    <ProductClientsCarouselBlock
      key={contentModule.sys.id}
      id={contentModule.sys.id}
      heading={contentModule.heading}
      items={items}
    />
  );
};

const renderClientsCarouselFilter = (
  contentModule: ProductContentModulesClientsCarouselFilter
) => {
  if (!contentModule.createFilterForClientCarousel) return null;

  const arrayOfFilters = contentModule.listOfFilters
    ?.split(", ")
    .sort((a, b) => a.localeCompare(b));

  return (
    <div className="relative z-10 mx-auto w-full max-w-screen-4xl px-5 lg:px-8 2xl:px-24">
      <Swiper
        id="filter-navigation"
        slidesPerView={"auto"}
        spaceBetween={10}
        loop={true}
        modules={[Navigation, Keyboard]}
        centerInsufficientSlides={true}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next"
        }}
        keyboard={{
          enabled: true
        }}
        className={`
          relative
          before:absolute
          before:inset-y-0
          before:left-0
          before:z-[2]
          before:h-full
          before:w-6
          before:bg-gradient-to-r
          before:from-white
          before:to-transparent
          after:absolute
          after:inset-y-0
          after:right-0
          after:z-[1]
          after:h-full
          after:w-6
          after:bg-gradient-to-l
          after:from-white
          after:to-transparent
          md:before:w-20
          md:after:w-20
          `}
      >
        {arrayOfFilters?.map((filter, index) => (
          <SwiperSlide
            id={`filter-${index + 1}`}
            key={contentModule.sys.id}
            className="flex min-w-max flex-1"
          >
            <ProductFilterButton key={contentModule.sys.id} heading={filter} />
          </SwiperSlide>
        ))}
        {/* { MOTION CONTROLS COMPONENT } */}
        <div id="filter-navigation-buttons" className="swiper-buttons relative">
          <button
            className="swiper-button-next rounded-full bg-ada-yellow-500 p-5 ease-in hover:bg-ada-green-400"
            name="Next"
          ></button>
          <button
            className="swiper-button-prev rounded-full bg-ada-yellow-500 p-5 ease-in hover:bg-ada-green-400"
            name="Previous"
          ></button>
        </div>
        {/* { UP ARROW COMPONENT } */}
        <a
          href="#filter-navigation"
          className="fixed bottom-2 right-5 z-10 rounded-full bg-ada-salmon-500 p-3 pb-3.5 text-white ease-in hover:bg-ada-teal-400 lg:right-8 lg:mx-0 2xl:right-24"
        >
          <Arrow className="h-3 w-5 -rotate-90 fill-white" />
        </a>
      </Swiper>
    </div>
  );
};

const renderSolutionFeatures = (
  contentModule: ProductContentModulesSolutionFeatures
) => {
  const items = filterNull(contentModule.solutionFeaturesCollection?.items);

  return (
    <ProductSolutionFeaturesBlock
      key={contentModule.sys.id}
      id={contentModule.sys.id}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      solutionsStyle={contentModule.solutionsStyle
        ?.toLowerCase()
        .split(" ")
        .join("")}
      items={items}
    />
  );
};

const renderTestimonials = (
  contentModule: ProductContentModulesTestimonials
) => {
  const image = contentModule.image;
  const sourceLogo = contentModule.sourceLogo;
  const items = filterNull(contentModule.servicesCollection?.items);

  return (
    <ProductTestimonialsBlock
      key={contentModule.sys.id}
      text={contentModule.text?.json as Document | undefined}
      image={image}
      sourceLogo={sourceLogo}
      sourceName={contentModule.sourceName}
      sourceTitle={contentModule.sourceTitle}
      ctaTitle={contentModule.ctaTitle}
      ctaUrl={contentModule.ctaUrl}
      servicesLabel={contentModule.servicesLabel}
      items={items}
    />
  );
};

const renderFeaturedImageWithText = (
  contentModule: ProductContentModulesFeaturedImageWithText
) => {
  const image = contentModule.image;

  return (
    <ProductFeaturedImageWithTextBlock
      key={contentModule.sys.id}
      eyebrow={contentModule.eyebrow}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      image={image}
      backgroundStyle={contentModule.backgroundStyle
        ?.toLowerCase()
        .split(" ")
        .join("")}
    />
  );
};

const renderPillars = (contentModule: ProductContentModulesPillars) => {
  const items = filterNull(contentModule.pillarsCollection?.items);

  return (
    <ProductPillarsBlock
      key={contentModule.sys.id}
      eyebrow={contentModule.eyebrow}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      items={items}
      orientation={contentModule.orientation?.toLowerCase().split(" ").join("")}
    />
  );
};

const renderRtGetStarted = (
  contentModule: ProductContentModulesRtGetStarted
) => {
  const items = filterNull(contentModule.cardsCollection?.items);
  const form = contentModule.form as ProductContentModulesRtGetStartedForm;
  return (
    <ProductRtGetStartedBlock
      key={contentModule.sys.id}
      heading={contentModule.heading}
      items={items}
      getStartedForm={form}
      cardsLayout={contentModule.cardsLayout?.toLowerCase().split(" ").join("")}
    />
  );
};

const renderCtaModal = (contentModule: ProductContentModulesCtaModal) => {
  return (
    <ProductCtaModal
      key={contentModule.sys.id}
      heading={contentModule.heading}
      subheading={contentModule.subheading}
      ctaTitle={contentModule.ctaTitle}
      ctaUrl={contentModule.ctaUrl}
      ctaStyle={contentModule.ctaStyle?.toLowerCase().split(" ").join("")}
    />
  );
};

const renderDropdownBlock = (
  contentModule: ProductContentModulesDropdownBlock
) => {
  const items = filterNull(contentModule.cardsCollection?.items);

  return (
    <ProductDropdownBlock
      key={contentModule.sys.id}
      heading={contentModule.heading}
      subheading={contentModule.subheading}
      items={items}
    />
  );
};

const renderAccordion = (contentModule: ProductContentModulesAccordion) => {
  const items = filterNull(contentModule.accordionGroupsCollection?.items);
  return (
    <ProductAccordionBlock
      key={contentModule.sys.id}
      heading={contentModule.heading}
      items={items}
    />
  );
};

const renderImage = (contentModule: ProductContentModulesImage) => {
  const image = contentModule.image;

  return (
    <ProductImageBlock
      key={contentModule.sys.id}
      image={image}
      caption={contentModule.caption?.json as Document | undefined}
      mobileImage={contentModule.mobileImage}
      orientation={contentModule.orientation?.toLowerCase().split(" ").join("")}
    />
  );
};

const renderImageCarousel = (
  contentModule: ProductContentModulesImageCarousel
) => {
  const items = filterNull(contentModule.imageCarouselGroupsCollection?.items);
  return (
    <ProductImageCarouselBlock
      key={contentModule.sys.id}
      id={contentModule.sys.id}
      items={items}
    />
  );
};

const renderRichText = (contentModule: ProductContentModulesRichText) => {
  return (
    <ProductRichTextBlock
      key={contentModule.sys.id}
      text={contentModule.text?.json as Document | undefined}
    />
  );
};

const renderClientsLinks = (
  contentModule: ProductContentModulesClientsLinks
) => {
  const items = filterNull(contentModule.clientsCollection?.items);
  return (
    <ProductClientsLinksBlock
      key={contentModule.sys.id}
      mobileHeading={contentModule.mobileHeading}
      heading={contentModule.heading}
      showLinksOnMobile={contentModule.showLinksOnMobile}
      text={contentModule.text?.json as Document | undefined}
      items={items}
    />
  );
};

const renderHeadingWithText = (
  contentModule: ProductContentModulesHeadingWithText
) => {
  const icon = contentModule.icon;

  return (
    <ProductHeadingWithTextBlock
      key={contentModule.sys.id}
      eyebrow={contentModule.eyebrow}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      icon={icon}
      backgroundStyle={contentModule.backgroundStyle
        ?.toLowerCase()
        .split(" ")
        .join("")}
      ctaTitle={contentModule.ctaTitle}
      ctaUrl={contentModule.ctaUrl}
    />
  );
};

const renderCaseStudyWithCard = (
  contentModule: ProductContentModulesCaseStudyWithCard
) => {
  const card = contentModule.card as Card;
  const caseStudy = contentModule.caseStudy as CaseStudy;
  console.log(contentModule);
  return (
    <ProductCaseStudyWithCardBlock
      key={contentModule.sys.id}
      title={contentModule.title}
      card={card}
      caseStudy={caseStudy}
      backgroundStyle={contentModule.backgroundStyle
        ?.toLowerCase()
        .split(" ")
        .join("")}
      cardsStyle={contentModule.cardsStyle}
    />
  );
};

const renderTable = (contentModule: ProductContentModulesTable) => {
  return (
    <ProductTableBlock
      key={contentModule.sys.id}
      heading={contentModule.heading}
      text={contentModule.text?.json as Document | undefined}
      backgroundStyle={contentModule.backgroundStyle
        ?.toLowerCase()
        .split(" ")
        .join("")}
    />
  );
};

const renderHtml = (contentModule: ProductContentModulesHtml) => {
  return (
    <ProductHtmlBlock key={contentModule.sys.id} html={contentModule.html} />
  );
};

const renderColumnCards = (
  contentModule: TrendReportContentModulesColumnCards
) => {
  const items = filterNull(contentModule.cardsCollection?.items);
  return (
    <div className="my-20 md:my-16 lg:my-24">
      <TrendReportColumnCards
        mobileColumns={contentModule.mobileColumns
          ?.toLowerCase()
          .split(" ")
          .join("")}
        items={items}
      />
    </div>
  );
};

const renderTabbedContent = (
  contentModule: TrendReportContentModulesTabbedContent
) => {
  const items = filterNull(contentModule.slidesCollection?.items);

  return (
    <div className="my-20 md:my-16 lg:my-24">
      <TrendReportTabbedContent
        id={contentModule.sys.id}
        heading={contentModule.heading}
        eyebrow={contentModule.eyebrow}
        html={contentModule.html}
        ctaStyle={contentModule.ctaStyle?.toLowerCase().split(" ").join("")}
        items={items}
      />
    </div>
  );
};

function renderContentModule(
  contentModule: ContentModule,
  index: number,
  length: number
): React.ReactNode {
  console.log(contentModule);
  switch (contentModule.__typename) {
    case "ProductCmCta":
      return renderCta(contentModule);
    case "ProductCmVideo":
      return renderVideo(contentModule);
    case "ProductCmServices":
      return renderServices(contentModule);
    case "ProductCmStatistics":
      return renderStatistics(contentModule);
    case "ProductCmImageWithText":
      return renderImageWithText(contentModule);
    case "ProductCmComparisonTable":
      return renderComparisonTable(contentModule);
    case "ProductCmCards":
      return renderCards(contentModule);
    case "ProductCmTransparentCards":
      return renderTransparentCards(contentModule);
    case "ProductCmClientsCarousel":
      return renderClientsCarousel(contentModule);
    case "ProductCmClientCarouselFilter":
      return renderClientsCarouselFilter(contentModule);
    case "ProductCmSolutionFeatures":
      return renderSolutionFeatures(contentModule);
    case "ProductCmTestimonials":
      return renderTestimonials(contentModule);
    case "ProductCmFeaturedImageWithText":
      return renderFeaturedImageWithText(contentModule);
    case "ProductCmPillars":
      return renderPillars(contentModule);
    case "ProductCmRtGetStarted":
      return renderRtGetStarted(contentModule);
    case "ProductCmAccordion":
      return renderAccordion(contentModule);
    case "ProductCmImage":
      return renderImage(contentModule);
    case "ProductCmImageCarousel":
      return renderImageCarousel(contentModule);
    case "ProductCmRichText":
      return renderRichText(contentModule);
    case "ProductCmClientsLinks":
      return renderClientsLinks(contentModule);
    case "ProductCmHeadingWithText":
      return renderHeadingWithText(contentModule);
    case "ProductCmCaseStudyWithCard":
      return renderCaseStudyWithCard(contentModule);
    case "ProductCmTable":
      return renderTable(contentModule);
    case "ProductCmHtml":
      return renderHtml(contentModule);
    case "ProductCmRtGetStartedThankYou":
      return renderRtGetStartedThankYou(contentModule);
    case "ProductCmCtaModal":
      return renderCtaModal(contentModule);
    case "ProductCmDropdownBlock":
      return renderDropdownBlock(contentModule);
    case "ProductCmWebinarBlock":
      return renderWebinarBlock(contentModule, index, length);
    case "ProductCmGatedContent":
      return renderGatedContent(contentModule);
    case "PricingCmImageWithText":
      return renderPricingImageWithText(contentModule);
    case "PricingCmTabbedContent":
      return renderPricingTabbedContent(contentModule);
    case "PricingCmGrid":
      return renderPricingGrid(contentModule);
    case "ProductCmSubscribeForm":
      return renderSubscribeForm(contentModule);
    case "ProductCmCtaImageWithText":
      return renderCtaImageWithText(contentModule);
    case "ProductCmComparisonChart":
      return renderComparisonChart(contentModule);
    case "ToadCalculator":
      return renderToadCalculator(contentModule);
    case "TrendReportColumnCards":
      return renderColumnCards(contentModule);
    case "TrendReportTabbedContent":
      return renderTabbedContent(contentModule);
  }
}

export function ProductContentModules({
  contentModules
}: Readonly<ProductContentModulesProps>) {
  return (
    <>
      {contentModules.map((contentModule, index) =>
        renderContentModule(contentModule, index, contentModules.length)
      )}
    </>
  );
}

export default ProductContentModules;
