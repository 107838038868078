import * as React from "react";
import {
  HomepageContentModulesCollectionItemGrid,
  HomepageContentModulesCollectionItemInterrupter,
  HomepageContentModulesCollectionItemList,
  HomepageContentModulesCollectionItemTopics,
  BlogPostContentModulesCollectionItemRichText,
  BlogPostContentModulesCollectionItemCta,
  BlogPostContentModulesCollectionItemPullQuote,
  BlogPostContentModulesCollectionItemImage,
  BlogPostContentModulesCollectionItemHtml,
  BlogPostContentModulesCollectionItemVideo,
  CtaLimitedType,
  CtaBlogSubscriptionType,
  CtaDemoRequestType,
  CtaFormType,
  CtaButtonType,
  CtaInterstitialTextType,
  CtaGatedContentType,
  BlogPost,
  Topic,
  ContentModule,
  ProductContentModulesCtaImageWithText
} from "@src/lib/services/server/contentful";
import {
  Grid,
  Interrupter,
  List,
  Topics,
  CtaBlock,
  ImageBlock,
  BlockQuote,
  RichTextBlock,
  HtmlBlock,
  VideoBlock
} from "@src/components";
import type { AssetsByIdQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import { contentfulEntryUrl, filterNull, formatDate } from "@src/lib/utils";
import { ProductCtaImageWithTextBlock } from "../ProductCtaImageWithText";

export type ContentModulesProps = {
  contentModules: ContentModule[];
  totalPerTopic?: { [topicId: string]: number };
  richTextAssets?: AssetsByIdQuery;
};

const renderGrid = (
  contentModule: HomepageContentModulesCollectionItemGrid
) => {
  const items = filterNull(contentModule.blogPostsCollection?.items);
  const cta = contentModule.cta as
    | CtaLimitedType
    | CtaBlogSubscriptionType
    | CtaDemoRequestType
    | CtaFormType;

  return (
    <Grid
      key={contentModule.sys.id}
      sectionHeading={contentModule.heading}
      items={items}
      cta={cta}
      responsive={true}
      borderTop={true}
    />
  );
};

const renderInterrupter = (
  contentModule: HomepageContentModulesCollectionItemInterrupter
) => {
  const secondaryInterrupter = filterNull(
    contentModule.blogPostsCollection?.items
  );
  const primaryInterrupter = secondaryInterrupter.shift();
  if (!primaryInterrupter) {
    return null;
  }
  const topics = filterNull(primaryInterrupter.topicsCollection?.items);
  const eyebrow = topics[0]?.title;
  const media = primaryInterrupter.previewImage || primaryInterrupter.leadImage;

  return (
    <Interrupter
      key={contentModule.sys.id}
      sectionHeading={contentModule.heading}
      url={contentfulEntryUrl(primaryInterrupter)}
      media={media}
      eyebrow={eyebrow}
      heading={primaryInterrupter.previewTitle || primaryInterrupter.title}
      meta={formatDate(primaryInterrupter.date)}
      text={
        primaryInterrupter.previewDescription || primaryInterrupter.description
      }
      items={secondaryInterrupter}
    />
  );
};

const renderList = (
  contentModule: HomepageContentModulesCollectionItemList
) => {
  const items = filterNull(contentModule.blogPostsCollection?.items);
  const cta = contentModule.cta as
    | CtaLimitedType
    | CtaBlogSubscriptionType
    | CtaDemoRequestType
    | CtaFormType;

  return (
    <List
      key={contentModule.sys.id}
      sectionHeading={contentModule.heading}
      items={items}
      cta={cta}
    />
  );
};

const renderTopics = (
  contentModule: HomepageContentModulesCollectionItemTopics,
  totalPerTopic?: { [topicId: string]: number }
) => {
  const items = filterNull(contentModule.topicsCollection?.items);

  return (
    <Topics
      key={contentModule.sys.id}
      sectionHeading={contentModule.heading}
      items={items}
      totalPerTopic={totalPerTopic}
    />
  );
};

const renderCta = (contentModule: BlogPostContentModulesCollectionItemCta) => {
  const cta = contentModule.cta as
    | CtaBlogSubscriptionType
    | CtaDemoRequestType
    | CtaButtonType
    | CtaInterstitialTextType
    | CtaGatedContentType
    | CtaFormType;

  return <CtaBlock key={contentModule.sys.id} cta={cta} />;
};

const renderImage = (
  contentModule: BlogPostContentModulesCollectionItemImage
) => {
  const media = contentModule.media;

  return (
    <ImageBlock
      key={contentModule.sys.id}
      media={media}
      caption={contentModule.caption?.json as Document | undefined}
    />
  );
};

const renderPullQuote = (
  contentModule: BlogPostContentModulesCollectionItemPullQuote
) => {
  const sourceMedia = contentModule.sourceMedia;

  return (
    <BlockQuote
      key={contentModule.sys.id}
      text={contentModule.quote}
      attribution={{
        media: sourceMedia,
        heading: contentModule.sourceName,
        text: contentModule.sourceTitle,
        ctaUrl: contentModule.ctaUrl,
        ctaTitle: contentModule.ctaTitle
      }}
    />
  );
};

const renderRichText = (
  contentModule: BlogPostContentModulesCollectionItemRichText,
  richTextAssets?: AssetsByIdQuery
) => {
  const text = contentModule.text?.json as Document | undefined;
  if (!text) {
    return null;
  }

  return (
    <RichTextBlock
      key={contentModule.sys.id}
      text={text}
      richTextAssets={richTextAssets}
    />
  );
};

const renderHtml = (
  contentModule: BlogPostContentModulesCollectionItemHtml
) => {
  return <HtmlBlock key={contentModule.sys.id} html={contentModule.html} />;
};

const renderVideo = (
  contentModule: BlogPostContentModulesCollectionItemVideo
) => {
  return (
    <VideoBlock
      key={contentModule.sys.id}
      video={contentModule.video}
      caption={contentModule.caption?.json as Document | undefined}
    />
  );
};

const renderCtaImageWithText = (
  contentModule: ProductContentModulesCtaImageWithText
) => {
  return (
    <div className="-mx-5 lg:-mx-0 lg:-my-6 lg:pr-5 xl:-ml-0 xl:pr-7">
      <ProductCtaImageWithTextBlock
        image={contentModule.image}
        text={contentModule.text?.json as Document | undefined}
      />
    </div>
  );
};

function renderContentModule(
  contentModule: ContentModule,
  totalPerTopic?: { [topicId: string]: number },
  richTextAssets?: AssetsByIdQuery
): React.ReactNode {
  switch (contentModule.__typename) {
    case "CmGrid":
      return renderGrid(contentModule);
    case "CmInterrupter":
      return renderInterrupter(contentModule);
    case "CmList":
      return renderList(contentModule);
    case "CmTopics":
      return renderTopics(contentModule, totalPerTopic);
    case "CmCta":
      return renderCta(contentModule);
    case "CmImage":
      return renderImage(contentModule);
    case "CmPullQuote":
      return renderPullQuote(contentModule);
    case "CmRichText":
      return renderRichText(contentModule, richTextAssets);
    case "CmHtml":
      return renderHtml(contentModule);
    case "CmVideo":
      return renderVideo(contentModule);
    case "ProductCmCtaImageWithText":
      return renderCtaImageWithText(contentModule);
  }
}

export function ContentModules({
  contentModules,
  totalPerTopic,
  richTextAssets
}: ContentModulesProps) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {contentModules.map(contentModule =>
        renderContentModule(contentModule, totalPerTopic, richTextAssets)
      )}
    </>
  );
}

export default ContentModules;
