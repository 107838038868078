/* eslint-disable tailwindcss/no-contradicting-classname */
import * as React from "react";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";
import { IContentfulImage } from "@src/lib/types";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText, ContentfulImage } from "@src/components";

export type PricingImageWithTextBlockProps = {
  heading?: string | null;
  text?: Document | null;
  image?: IContentfulImage | null;
  subtext?: string | null;
  backgroundStyle?: string | null;
};

export function PricingImageWithTextBlock({
  heading,
  text,
  image,
  subtext,
  backgroundStyle
}: PricingImageWithTextBlockProps) {
  const defaultBackgroundStyle: string = "yellow";

  const backgroundClassNames: { [key: string]: string } = {
    yellow: "bg-yellow pt-10 lg:pt-[150px]",
    white: "bg-white py-12 md:py-16 lg:py-[54px]"
  };

  const backgroundClassName =
    backgroundClassNames[
      backgroundStyle ? backgroundStyle : defaultBackgroundStyle
    ];

  const imageTransforms: ContentfulImageUrlTransforms = {
    width: image?.width || 120,
    height: image?.height || 177
  };

  return (
    <div className={` ${backgroundClassName}`}>
      <ProductContainer>
        <div className="mx-auto w-full max-w-[1180px] space-y-4 text-center">
          <div className="space-y-4">
            {heading && <h2 className={`text-[40px] font-bold`}>{heading}</h2>}
            {text && (
              <div className="[&_a]:link-underline-animated-sm text-[21px] leading-[30px] [&_a]:text-pr-teal-600">
                <RichText text={text} />
              </div>
            )}
          </div>
          {image && (
            <figure className="mx-auto max-w-[120px] pt-10">
              <ContentfulImage image={image} transforms={imageTransforms} />
            </figure>
          )}
          {subtext && (
            <div className="mx-auto max-w-[850px] pt-12 text-[21px] leading-[30px] md:pt-16 lg:pt-[75px] lg:text-[15px] lg:leading-[18px] ">
              {subtext}
            </div>
          )}
        </div>
      </ProductContainer>
    </div>
  );
}

export default PricingImageWithTextBlock;
