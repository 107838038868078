import React, { useRef } from "react";
import Plyr, { APITypes } from "plyr-react";
import "plyr-react/dist/plyr.css";
import getVideoId from "get-video-id";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer, RichText } from "@src/components";
import { PlyrVideo } from "../PlyrVideo";

export type ProductVideoBlockProps = {
  heading?: string | null;
  video?: string | null;
  caption?: Document;
  orientation?: string | null;
  backgroundStyle?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

export function ProductVideoBlock({
  heading,
  video,
  caption,
  orientation = "contained",
  backgroundStyle
}: ProductVideoBlockProps) {
  const defaultBackgroundStyle: string = "white";

  const backgroundClassNames: { [key: string]: string } = {
    yellow: "bg-pr-yellow-200 py-10 lg:py-16",
    white: "bg-white"
  };

  const backgroundClassName =
    backgroundClassNames[
      backgroundStyle ? backgroundStyle : defaultBackgroundStyle
    ];

  return (
    <div className={`${backgroundClassName} my-10 md:my-16 lg:mb-12 lg:mt-24`}>
      <ProductContainer>
        <div
          className={`mx-auto space-y-6 lg:space-y-8 ${
            orientation == "contained" ? "max-w-[940px]" : ""
          }`}
        >
          {heading && (
            <h2 className="text-center text-[30px] font-bold lg:text-[40px]">
              {returnLineBreaks(heading)}
            </h2>
          )}
          <figure className="space-y-1 md:space-y-4">
            <div className="overflow-hidden rounded-2xl md:rounded-3xl">
              <PlyrVideo video={video} />
            </div>
            {caption && caption.content.length > 0 && (
              <figcaption className="type-pr-eta text-pr-dark-gray-400">
                <RichText text={caption} />
              </figcaption>
            )}
          </figure>
        </div>
      </ProductContainer>
    </div>
  );
}

export default ProductVideoBlock;
